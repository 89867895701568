<template>
  <div id="app">
    <div id="menu" v-if="$route.fullPath !== '/'">
      <router-link class="menu-item" to="/about">About</router-link>
      <router-link class="menu-item" to="/history">History</router-link>
      <router-link class="menu-item" to="/locations">Locations</router-link>
      <router-link class="menu-item" to="/npc">NPC</router-link>
      <router-link class="menu-item btn-turn-off float-right" to="/" :exact="true">Turn OFF</router-link>
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: 'APP',
  components: {},
  data: function() {
    return {

    };
  },
  created() {

  },
  methods: {}
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sigmar&display=swap');
@import "@/assets/scss/styles";

@media (max-width: 768px) {
  .btn-turn-off {
    display:none!important;
  }
}
</style>
