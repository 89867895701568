<template>
  <div id="static">
    <section></section>
    <svg>
      <filter id="noise">
        <feTurbulence id="turbulence">
          <animate
            attributeName="baseFrequency"
            dur="50s"
            values="0.9 0.9;0.8 0.8; 0.9 0.9"
            repeatCount="indefinite"
          ></animate>
        </feTurbulence>
        <feDisplacementMap in="SourceGraphic" scale="60"></feDisplacementMap>
      </filter>
    </svg>
    <div id="wrapper">
      <h1 class="glitch" data-text="sunnyvale">Sunnyvale</h1>
      <span class="sub">the safest city of california</span>
    </div>

    <div class="enter-wrapper">
      <router-link to="/about">
        <button class="btn btn-primary">Enter</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
    name: "Home",
    components: { }
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  section {
    position: absolute;
    top: -50px;
    left: -50px;
    width: calc(100% + 50px);
    height: calc(100% + 50px);
    background: repeating-linear-gradient(#111, #111 50%, white 50%, white);
    background-size: 5px 5px;
    filter: url(#noise);
  }

  svg {
    width: 0;
    height: 0;
  }

  .enter-wrapper {
    position:relative;
    top: 230px;

    .btn {
      background: #b4121a;
      border: 0;
      color: #fff;
      font-size: 30px;
      padding: 20px 40px;
      border-radius: 10px;
      font-family: 'Racing Sans One', cursive;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #cc3d44;
      }
    }
  }

  #static {
    background: #000;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
  }

  #wrapper {
    text-align: center;
    position:absolute;
  }

  .sub {
    color: #ff4a54;
    font-size:30px;
    font-weight:bold;
    letter-spacing: 0.4em;
    background:yellow;
    padding: 10px 20px 10px 35px;
  }

  @mixin glitchCopy {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch {
    position: relative;
    color: darkmagenta;
    font-size: 10em;
    font-family: 'Racing Sans One', cursive;
    letter-spacing: 0.1em;
    animation: glitch-skew 1s infinite linear alternate-reverse;
    margin-bottom:20px;

    &::before {
      @include glitchCopy;
      left: 2px;
      text-shadow: -2px 0 #ff00c1;
      clip: rect(44px, 450px, 56px, 0);
      animation: glitch-anim 5s infinite linear alternate-reverse;
    }

    &::after {
      @include glitchCopy;
      left: -2px;
      text-shadow: -2px 0 #ff00c1, 2px 2px #ff00c1;
      clip: rect(44px, 450px, 56px, 0);
      animation: glitch-anim2 5s infinite linear alternate-reverse;
    }
  }

  @keyframes glitch-anim {
    $steps: 20;
    @for $i from 0 to $steps {
      #{percentage($i*(1/$steps))} {
        clip: rect(random(100) + px, 9999px, random(100) + px, 0);
        transform: skew((random(100) / 100) + deg);
      }
    }
  }

  @keyframes glitch-anim2 {
    $steps: 20;
    @for $i from 0 to $steps {
      #{percentage($i*(1/$steps))} {
        clip: rect(random(100) + px, 9999px, random(100) + px, 0);
        transform: skew((random(100) / 100) + deg);
      }
    }
  }

  @keyframes glitch-skew {
    $steps: 10;
    @for $i from 0 to $steps {
      #{percentage($i*(1/$steps))} {
        transform: skew((random(10) - 5) + deg);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .glitch {
      font-size: 3.5em;
    }

    .sub {
      font-size: 16px;
      padding:5px;
    }

    .enter-wrapper{
      position:relative;
      top: 130px;

      .btn {
        font-size: 26px;
        padding: 10px 20px;
      }
    }
  }

</style>
