import Vue from 'vue'
import VueRouter from 'vue-router'
import Intro from '../views/Intro.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Intro',
    component: Intro
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/About.vue')
  },
  {
    path: '/locations',
    name: 'Locations',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Locations.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/History.vue')
  },
  {
    path: '/npc',
    name: 'NPC',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/NPC.vue')
  },
  // {
  //   path: '/generator/custom',
  //   name: 'Generator Custom',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/GeneratorVariant.vue')
  // },
  // {
  //   path: '/generator/:type',
  //   name: 'Generator Type',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/GeneratorType.vue')
  // },
  // {
  //   path: '/generator/:type/:variant',
  //   name: 'Generator Variant',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/GeneratorVariant.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  next();
})
export default router
